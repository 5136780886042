section.all-events {
    @extend section.event;
    
    .card .card-body {
        padding: 1.25rem;
    }
}
section.page {
    .pagination li a {
        color: black;
    }
    .pagination li.active a {
        background: $warning;
        border-color: $warning;
    }
}