section.landing-page {
    // min-height: 50vh;
    // background: url(../img/landing1.jpeg) no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    z-index: 1; 
    // &:after {
    //     content: "";
    //     background-color: rgba(0, 0, 0, 0.65);
    //     position: absolute;
    //     z-index: -1;
    //     top: 0;
    //     left: 0;
    //     bottom: 0;
    //     right: 0;
    // }
    nav.bg-opacity {
        background: rgba(0, 0, 0, 0.7);
        // background: rgba(0, 65, 111, 0.7);
        // background: rgba(0, 81, 140, 0.7);
    }
    a:hover {
        text-decoration: none;
        color: white;
    }
    button:hover {
        a {
            color: white;
        }
    }
    .welcome {
        margin-top: 3rem;
        color: rgba(255, 255, 255, 0.747);
        padding-bottom: 2rem;
    }
}

.page-content{
    min-height: 480px;
    min-height: 85vh;
}