section.about-nav {
    nav.bg-opacity {
        background: rgba(0, 0, 0, 0.7);
    }
    a:hover {
        text-decoration: none;
        color: white;
    }
    button:hover {
        a {
            color: white;
        }
    }
}
section.emilius {
    display: none;
}