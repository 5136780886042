// Import core styles
@import "variables";
@import "mixins";

// Additional typography
@import "typography";

// Animations
@import "animate";

// Components
@import "aside";
@import "avatars";
@import "badge";
@import "breadcrumb-menu";
@import "breadcrumb";
@import "buttons";
@import "callout";
@import "card";
@import "charts";
@import "dropdown";
@import "footer";
@import "grid";
@import "input-group";
@import "loading";
@import "modal";
@import "nav";
@import "navbar";
@import "progress";
@import "sidebar";
@import "switches";
@import "tables";
@import "widgets";

// Layout Options
@import "layout";

@import "others";

// Utility classes
@import "utilities";

// Temporary fixes
@import "temp";

// Right-to-left
@import "rtl";
