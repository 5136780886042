a {
    outline: none;
}

// LIST OF STAFF
.list-of-staff {
    i.icon-ban {
        &:hover {
            color: #F86C6B;
        }
    }
}

// ADMIN DASHBOARD DATATABLE
.dataTables_wrapper .dataTables_paginate {
    margin-top: 5px;
}

table.dataTable.no-footer {
    border-bottom: 1px solid rgb(190, 190, 190) !important;
}

table.dataTable thead th,
table.dataTable thead td {
    border-bottom: 1px solid rgb(190, 190, 190);
    border-bottom: none !important;
}

.dataTables_wrapper .dataTables_filter input {
    border: 1px solid rgb(190, 190, 190);
    border-radius: 4px;
    padding: 2px 5px;
    outline: none;
}

table.dataTable thead th:last-child {
    text-align: center;
}

table.dataTable tbody tr {
    >td:last-child {
        text-align: center;
        min-width: 130px;
        max-width: 130px;
    }
    td {
        a:hover {
            text-decoration: none;
        }
    }
}

.table-bordered {
    th {
        color: #444;
        color: #3e515b;
        &:first-child {
            border-left: none;
        }
        border-top: none;
    }
    td {
        &:first-child {
            border-left: none;
        }
    }
}

.table-bordered thead th,
.table-bordered td {
    border-bottom: none;
    border-right: none;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.03);
}

.card-header {
    i.icon-people,
    i.icon-user-follow,
    i.icon-settings,
    i.icon-home {
        color: darken(rgb(190, 190, 190), 18%); // border: 1px solid rgb(190, 190, 190); 
        padding: 6px 8px;
        border-radius: 2px;
        background: rgb(80, 145, 82);
        color: white;
        background: #FFC107;
        margin-right: 5px;
    }
    a.add-staff {
        padding: 5px 16px;
        color: #FFF; // border-radius: 1px;
        background: #20A8D8;
        border: 1px solid darken(#20A8D8, 5%);
        &:hover {
            text-decoration: none;
            background-color: lighten(#20A8D8, 5%);
        }
        &.green {
            background: #4dbd74;
            color: white;
            // border-color: #4dbd74;
            border-color: #3a9d5d;
        }
        &.green:hover {
            text-decoration: none;
            background-color: #3ea662;
            border-color: #3a9d5d;
        }
    }
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 5px 12px;
    &.current {
        border-color: rgb(190, 190, 190);
    }
}

.pagination li a,
.page-link {
    padding: 5px 10px;
    margin-right: 5px;
    border-radius: 2px;
    border: 1px solid #c4c4c4;
}

.breadcrumb {
    margin-bottom: 20px;
}

// MEMBER - USER - PROFILE
.user-profile {
    a {
        outline: none;
        &:hover {
            text-decoration: none;
        }
    }
    .card {
        border: none;
        padding: 20px;
    }
    .user-image {
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 1px solid lighten(#c2cfd6, 10%);
        text-align: center;
        margin-bottom: 20px;
        img {
            border: 1px solid darken(#20A8D8, 8%);
            border: 1px solid #c2cfd6;
            padding: 5px;
            max-width: 130px;
            border-radius: 4px;
        }
        .name {
            font-weight: bold;
            text-transform: uppercase;
            word-spacing: 2px;
        }
        .user_name {
            font-weight: bold;
            padding-bottom: 15px;
            color: #444;
        }
    }
    .user-navigation {
        width: 100%;
        nav {
            margin: 0;
            padding: 0;
        }
        ul {
            padding: 0px;
            margin: 0px;
            width: 100%; // border-bottom: 1px solid lighten(#c2cfd6, 10%);
            // text-align: center;
            li {
                display: inline-block;
                list-style-type: none; // margin-bottom: 10px;
                margin-right: 4px;
            }
            a {
                display: block;
                text-decoration: none;
                padding: 4px 20px;
                border-bottom: 2px solid #c2cfd6;
                border-color: white;
                &.active-link {
                    border-bottom-color: #F86C6B;
                }
                &:hover {
                    border-color: lighten(#F86C6B, 6%);
                }
            }
        }
    }
    .personal-info-1 {}
    .personal-info-2 {}
    .personal-info-1,
    .personal-info-2 {
        margin-top: 20px;
        min-height: 200px;
        border: 1px solid lighten(#c2cfd6, 6%);
        >div {
            &.row {
                margin-left: 0px;
                margin-right: 0px;
            }
            >div:first-child {
                font-weight: 500;
            }
            padding-top: 8px;
            padding-bottom: 8px;
            border-bottom: 1px solid lighten(#c2cfd6, 10%);
            overflow: hidden;
            &:nth-child(2n+1) {
                background: #f7f7f7;
            }
            &:last-child {
                border-bottom: none;
            }
        }
    } //member academic qualifications
    .academic-holder,
    .nextofkin-holder,
    .events-holder,
    .training-holder,
    .fee-holder {
        margin-top: 20px;
        .table {
            color: #444;
            thead th {
                border-bottom: none;
                padding-bottom: 8px;
                padding-top: 8px;
                font-weight: 500;
            }
            td,
            th {
                padding-bottom: 8px;
                padding-top: 8px; //border-top-color: #e1e7eb;
                border-top-color: lighten(#c2cfd6, 6%);
            }
            .table-striped tbody tr:nth-of-type(odd) {
                background-color: #F7F7F7;
            }
        }
    }
}

//MODAL MESSAGES
.modal-footer.delete {
    display: block;
    text-align: center;
}

.modal-header {
    background: lighten(#4dbd74, 20%);
    background: #E4E5E6;
    background: #F0F3F5;
    padding-bottom: 12px;
    padding-top: 12px;
}

// ADD A STAFF - LOGIN CREDENTIALS
.system-credentials {
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 12px;
    margin-bottom: 10px;
    color: #444;
    border-bottom: 1px dashed #c2cfd6;
    text-transform: uppercase;
}

// NAVIGATION TO ADD-EDIT-SET PRIVILLAGES
.edit-add-nav {
    nav {
        padding: 0px;
        margin-top: 20px;
        a {
            display: inline-block;
            border: 1px solid #20a8d8;
            padding: 4px 12px;
            border-radius: 2px;
            outline: none;
            &:hover {
                text-decoration: none;
                border-color: #F86C6B;
                border-color: #4dbd74;
            }
        }
    }
}

.double-action {
    width: 100%;
    a {
        display: block;
        width: 45%;
        border-right: 1px solid #c2cfd6;
        display: inline-block;
        text-align: center;
        &:last-child {
            border-right: none;
            &:hover {
                color: #F86C6B;
            }
        }
    }
}

body.modal-open {
    width: 100% !important;
    padding-right: 0 !important;
    position: fixed !important;
}

.user-roles {
    .table thead th {
        border-bottom: none;
        border-top: none;
    }
}

input[type="submit"] {
    cursor: pointer;
}

@media screen and (max-width: 810px) {
    table {
        display: inline-block;
        overflow-x: auto;
    }
}

.modal-assign-roles {
    form {
        >div:first-child {
            font-weight: bold;
            padding-bottom: 5px;
        }
        >div:nth-child(n+1) {
            // background: #F7F7F7;
            border-bottom: 1px solid lighten(#c2cfd6, 10%);
            padding-top: 10px;
        }
        >div:last-child {
            border-bottom: none;
        }
    }
}

.app-header .navbar-nav .dropdown-menu-right {
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175)
}

.form-error {
    color: red;
}