// Here you can add other styles
$box-shadow-16: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
$box-shadow-8: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
$box-shadow-6: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
$box-shadow-4: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
$box-shadow-3: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
$box-shadow-2: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

$btn-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

.shadow-2 {
	box-shadow: $box-shadow-2;
}
.shadow-3 {
	box-shadow: $box-shadow-3;
}
.shadow-4 {
	box-shadow: $box-shadow-4;
}
.shadow-6 {
	box-shadow: $box-shadow-6;
}
.shadow-8 {
	box-shadow: $box-shadow-8;
}
.shadow-16 {
	box-shadow: $box-shadow-16;
}
.btn-raised {
    box-shadow: $btn-shadow;
}

html{
    overflow-y: scroll;
}


.btn-file-upload {
	border: 2px solid darken(#20a8d8,10%);
	border-radius: 0px;
	padding: 5px 5px;
	margin: 2px;
	background: #20a8d8;
	display: inline-block;
	color: #fff;
	input[type="file"] {
		position: fixed;
		top: -1000px;
	}
	&:hover {
		background: darken(#20a8d8,15%);
		cursor: pointer;
	}
	&:active {
		background: #CCF;
	}
	&:invalid + span {
		color: #A44;
	}
	&:valid + span {
		color: #4A4;
	}
}

.font-10 {font-size: 10px;}
.font-12 {font-size: 12px;}
.font-14 {font-size: 14px;}
.font-16 {font-size: 16px;}
.font-18 {font-size: 18px;}
.font-20 {font-size: 20px;}
.font-22 {font-size: 22px;}
.font-24 {font-size: 24px;}
.font-26 {font-size: 26px;}
.font-28 {font-size: 28px;}
.font-30 {font-size: 30px;}

.hide {
	display: none;
}