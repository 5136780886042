//Google Map
#map {
    height: 100%;
    min-height: 300px;
    h6 {
        margin-bottom: 1px;
    }
}
.contact {
    .mdl-textfield {
        margin-bottom: -30px;
    } 
}
.breadcrumb {
    border-radius: 0px;
    padding: 0.2rem 1rem;
}

section.contact {
    form {
        label {
            margin-bottom: 0;
            margin-top: 0.5rem;
            transition: all 0.3s ease-out;
            color: rgb(97, 97, 97);
        }

        input, textarea{
            background: none;
            border: none;
            border-bottom: 2px solid rgb(162, 162, 162);
            width: 100%;
            font-family: 'Helvetica', sans-serif;
            font-size: 1.0rem;
            transition: all 0.3s;
        }
        input:focus, input:valid, textarea:focus, textarea:valid {
            outline: none;
            border-bottom: 2px solid #00aced;
        }
    }

}