section.single-event {
    .card {
        color: black;
        background: transparent;
        border-color: rgba(0, 0, 0, 0.467);
        .card-header {
            background: rgba(0, 0, 0, 0.467);
            color: rgba(255, 255, 255, 0.575);
        }
        .card-body {
            .table {
                &.table-bordered {
                    border-color: rgba(0, 0, 0, 0.467);
                    td, th, tr {
                        border-color: rgba(0, 0, 0, 0.467);
                        color: rgba(255, 255, 255, 0.878);
                    }
                    th {
                        color: rgba(255, 255, 255, 0.575);
                    }
                }
            }
        }
    }
}