section.guest {
    a {
        color: white;
        text-decoration: none;
    }
}

section.event {
    a {
        color: rgb(0, 0, 0);
        text-decoration: none;
    }
    .card {
        background: transparent;
        color: black;
        border: 0;
        margin: 0;
        .card-header {
            padding-top: 0;
            background: transparent;
            color: white;
            text-align: center;
        }
        .card-body {
            padding: 0.4rem 1.25rem;
            .picha {
                width: 120px;
                margin-right: 0.5rem;
            }
            p {
                margin-bottom: 0;
                color: white;
                a {
                    color: $warning;
                }
            }
            span {
                color: rgb(160, 160, 160);
            }
        }
    }
}

// LEFT-SIDEBAR LINKS
.sidebar-link-content {
    border: 1px solid #a4c7e0;
    border-color: #b0cde2;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 4px; // background: #f5f5f5;
    background: #FCFCFC;
    .sl-header {
        font-weight: 700;
        padding-bottom: 5px;
        margin-bottom: 8px;
        border-bottom: 1px solid #E0A800;
        border-bottom-color: lighten(#e48532, 2%);
        color: #444;
    }
    .sl-body {
        a {
            display: block;
            padding: 5px 10px;
            border: 1px solid #c3e6ff;
            border-color: #cce0ef;
            margin-bottom: 8px;
            border-radius: 3px;
            color: darken(#20a8d8, 2%);
            &.last {
                margin-bottom: 0px;
            }
            &:hover {
                text-decoration: none;
                border-color: #edddad;
                background: #f9f9f9;
                border-color: lighten(#52ce88, 4%);
                color: darken(#20a8d8, 5%);
            }
        }
    }
}

// EVENTS SECTION
.upcoming-events {
    padding: 20px;
    background: #F5F5F5;
    .events-header {
        border-bottom: 1px solid #a4d8ff;
        padding-bottom: 10px; // margin-bottom: 10px;
    }
    .event {
        border: 1px solid #e4e4e4;
        min-height: 120px;
        background: ghostwhite;
        background: #FCFCFC;
        margin-top: 20px;
        border-radius: 4px;
        padding: 10px;
        overflow: hidden;
        .event-date {
            border: 1px solid #fb503b; // border-color: #fb943b;
            border-color: #e48532;
            border-radius: 4px;
            text-align: center;
            >div:first-child {
                background: #fb503b; // background: #fb943b;
                background: #e48532;
                color: white;
                padding-top: 4px;
                padding-bottom: 4px;
                font-weight: bold;
            }
            >div:nth-child(2) {
                font-weight: bold;
                font-size: 1.75em;
            }
            >div:last-child {
                font-weight: 700;
                padding-top: 2px;
                padding-bottom: 2px;
                border-top: 1px solid #fb503b; // border-top-color: #fb943b;
                border-top-color: #e48532;
                color: #777;
            }
        }
        .event-body {
            .event-desc {
                margin-bottom: 10px;
            }
            .event-location-apply {
                >div:first-child {
                    margin-bottom: 10px;
                }
                i {
                    padding: 5px 8px;
                    background: #e4e5e6;
                    margin-right: 10px;
                    border-radius: 50%;
                    color: #fb503b;
                }
            }
        }
    }
}

.btn-read-more {
    background: #f3f3f3;
    border: 1px solid #a4d8ff;
}

.events-pagination {
    margin-top: 20px;
    border-top: 1px solid #a4d8ff;
    padding-top: 20px;
    text-align: center;
    span {
        background: #FCFCFC;
        border: 1px solid #d1d1d1;
        padding: 4px 10px;
        &:hover{
            cursor: pointer;
        }
    }
}