section.member-reg {
    margin-top: 20px;
    .card {
        box-shadow: $box-shadow-6;
    }
    span.input-group-addon {
        min-width: 100px;
        background: lighten($primary, 37%);
        //background: #bce6f5;
    }
    fieldset {
        margin-bottom: 20px; // border-bottom: 1px solid rgba(0, 0, 0, 0.186);
        // &:nth-last-child(2) {
        //     border-bottom: none;
        // }
        &:nth-child(even) {
            span.input-group-addon {
                // background: darken($primary, 10%);
                // color: white;
            }
        }
        legend {
            border-bottom: 2px solid $success;
            margin-bottom: 1rem;
        }
    }
    .inputfile {
        z-index: -1;
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
    }
    .inputfile+label {
        outline: none;
        color: black;
        cursor: pointer;
        padding: 8px 8px;
        font-size: 1.25em;
        display: inline-block;
        border: 1px solid #999;
        text-shadow: 1px 1px rgb(221, 221, 221);
        background: -webkit-linear-gradient(top, #f0f3f5, #c2cfd6);
    }
}