/*======================================================
=            Member Personal Details Styles            =
======================================================*/
#image-preview {

	img {
		min-width: 250px;
		max-width: 250px;
		min-height: 250px;
		max-height: 250px;
		border: 1px solid #ccc;
	}
}


/*=====  End of Member Personal Details Styles  ======*/
.dashboard{
	a{
		&:hover{
			text-decoration: none;
		}
	}
	.card{
		.card-body{
			h2, h3{
				border-bottom: 1px solid #a4e7ff;
				text-shadow: 1px 1px 1px #333;
			}
		}
	}
	.member-data, .admin-data{
		background: #FFF;
		padding: 20px;
		.u-event-header, .applications-header, .fees-header{
			border-bottom: 1px solid #aeeaff; margin-bottom: 30px;
		}
	}

	.dataTables_wrapper .dataTables_filter input{
		border-radius: 0px;
	}
}