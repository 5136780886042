$socialColours:
(
        facebook: #3b5998,
        twitter: #00aced,
        google: #dd4b39,
        linkedin: #007bb6,
        youtube: #bb0000,
        instagram: #517fa4,
        pinterest: #cb2027,
        flickr: #ff0084,
        tumblr: #32506d,
        forusquare: #0072b1,
        dribbble: #ea4c89,
        vine: #00bf8f
);


.fa-facebook {
  color:  #3b5998;
}
.fa-twitter {
  color:  #00aced;
}
.fa-instagram {
  color:  #517fa4 ;
}
.fa-linkedin {
  color:  #007bb6 ;
}
.fa-whatsapp {
  color:  #64D448 ;
}
.fa-youtube {
  color:  #bb0000 ;
}