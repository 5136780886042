section.candidacy-reg {
    .card {
        color: black;
        background: transparent;
        border-color: rgba(0, 0, 0, 0.467);
        .card-header {
            background: rgba(0, 0, 0, 0.467);
            color: rgba(255, 255, 255, 0.575);
        }
    }
    form{
        display: block;
        position: relative;
        padding: 15px 30px;

        fieldset {
            legend {
                margin-bottom: 1.2rem;
                background: $warning;
                padding: 0.5rem 1rem;
                box-shadow: $btn-shadow;
                &+ .row {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }
        }

        label{
            display: block;
            position: relative;
            top: -23px;
            left: 0px;
            color: rgb(82, 82, 82);
            font-family: 'Helvetica', sans-serif;
            font-size: 16px;
            z-index: 1;
            transition: all 0.3s ease-out;
        }


        input, textarea{
            display: block;
            position: relative;
            background: none;
            border: none;
            border-bottom: 1px solid rgb(162, 162, 162);
            width: 100%;
            font-family: 'Helvetica', sans-serif;
            font-size: 1.1rem;
            z-index: 2;
        }
        
        input:focus, input:valid, textarea:focus, textarea:valid {
            outline: none;
            border-bottom: 1px solid #00aced;
        }
    
    
        input:focus + label, input:valid + label, textarea:focus + label, textarea:valid + label {
            top: -40px;
            font-size: 11px;
            color: #00aced;
        }
    
        select {
            @extend input;
            &:valid {
                border-bottom: 1px solid rgb(162, 162, 162);
            }
            outline: none;
            option.gray {
                color: darkgrey;
            }
        }
    }

}