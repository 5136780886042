.mdl-mega-footer {
    padding: 14px 40px 9px;
    color: rgb(158, 158, 158); 
    background-color: rgb(66, 66, 66);
    // background-color: #213f6b;
}

/*******************************           ****************************************/

.footer-top:after,
.footer-middle:after,
.footer-bottom:after {
    content: '';
    display: block;
    clear: both;
}

/*******************************          ****************************************/

.footer-left {
    margin-bottom: 16px;
}

.footer-right {
    margin-bottom: 16px;
}

.footer-right a {
    display: block;
    margin-bottom: 16px;
    color: inherit;
    text-decoration: none;
}

/*******************************            ****************************************/

@media screen and (min-width: 760px) {
    .footer-left {
        float: left;
    }
    .footer-right {
        float: right;
    }
    .footer-right a {
        display: inline-block;
        margin-left: 16px;
        line-height: 36px;
        vertical-align: middle;
    }
}

/*******************************SOCIAL-BTN****************************************/

.social-btn {
    width: 36px;
    height: 36px;
    padding: 0;
    margin: 0;
    background-color: rgba(255, 252, 252, 0.863);
    border: none;
}

/*******************************DROPDOWN****************************************/

.footer-dropdown {
    display: block;
    position: relative;
}

@media screen and (min-width: 760px) {
    .footer-dropdown {
        width: 33%;
    }
    .footer-dropdown:nth-child(1),
    .footer-dropdown:nth-child(2) {
        float: left;
    }
    .footer-dropdown:nth-child(3) {
        float: right;
    }
    .footer-dropdown:nth-child(3):after {
        clear: right;
    }
    .footer-dropdown:nth-child(4) {
        clear: right;
        float: right;
    }
    .footer-middle:after {
        content: '';
        display: block;
        clear: both;
    }
}

@media screen and (min-width: 1024px) {
    .footer-dropdown,
    .footer-dropdown:nth-child(3),
    .footer-dropdown:nth-child(4) {
        width: 24%;
        float: left;
    }
    .footer-middle {
        display: flex;
        justify-content: center;
        padding-left: 10%;
    }
}

/*******************************checkbox****************************************/

.footer-checkbox {
    position: absolute;
    width: 100%;
    height: 55.8px;
    padding: 32px;
    margin: 0;
    margin-top: -16px;
    cursor: pointer;
    z-index: 1;
    opacity: 0;
}

.footer-checkbox+.footer-heading:after {
    font-family: 'FontAwesome';
    content: '\f0d8';
}

.footer-checkbox:checked~.footer-list {
    display: none;
}

.footer-checkbox:checked+.footer-heading:after {
    font-family: 'FontAwesome';
    content: '\f0d7';
}

/*******************************footer-heading****************************************/

.footer-heading {
    position: relative;
    width: 100%; // padding-right: 39.8px;
    margin-bottom: 6px;
    box-sizing: border-box;
    font-size: 14px; // line-height: 23.8px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: rgb(224, 224, 224);
}

.footer-heading:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 23.8px;
    height: 23.8px;
    background-size: cover;
}

/*******************************footer-list****************************************/

.footer-list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 2px;
}

.footer-list:after {
    clear: both;
    display: block;
    content: '';
}

.footer-list li {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    line-height: 20px;
}

.footer-list a {
    color: inherit;
    text-decoration: none;
    white-space: nowrap;
}

/*******************************footer-checkbox****************************************/

@media screen and (min-width: 760px) {
    .footer-checkbox {
        display: none;
    }
    .footer-checkbox+.footer-heading:after {
        content: '';
    }
    .footer-checkbox:checked~.footer-list,
    .footer-checkbox:checked+.footer-heading+.footer-list {
        display: block;
    }
    .footer-checkbox:checked+.footer-heading:after {
        content: '';
    }
}

/*******************************footer-bottom ****************************************/

.footer-bottom {
    padding-top: 16px;
    border-top: 1px groove rgb(233, 233, 233);
    // border-top-color: #206aa0;
    display: flex;
    justify-content: center;
    padding-bottom: 0;
}

.footer-bottom .footer-list li {
    float: left;
    margin-bottom: 0;
    margin-right: 16px;
}